<template>
  <div class="mt-3">
    <v-card class="mt-1" outlined>
      <v-card-title class="text-no-wrap pt-1 ps-2 mt-2 mb-1">
        Configuração da meta
      </v-card-title>
      <v-card-subtitle class="text-no-wrap ps-2">
        Descrição detalhada que explique ao usuário a sua funcionalidade e
        importancia
      </v-card-subtitle>
      <v-card-text class="d-flex align-center mt-2 pb-2 ps-2">
        <FormConfiguracaoMeta ref="formConfiguracaoMeta"></FormConfiguracaoMeta>
      </v-card-text>
    </v-card>
    <v-card class="mt-2" outlined>
      <v-card-title class="text-no-wrap pt-1 ps-2 mt-2 mb-1">
        Distribuição de valores
      </v-card-title>
      <v-card-subtitle class="text-no-wrap ps-2">
        Descrição detalhada da distribuição de valores que explique ao usuário a
        sua funcionalidade e importancia
      </v-card-subtitle>
      <v-card-text class="d-flex align-center mt-2 pb-2 ps-2">
        <v-row class="mt-2 pa-3">
          <!-- <Grid :tamanho="12">
            <h4>Calcular automaticamente</h4>
            <v-switch
              hide-details="auto"
              v-model="calculoAutomatico"
              class="mt-0"
              :disabled="mesesPeriodicidades >= 2 ? false : true"
            ></v-switch>
          </Grid> -->
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12">
            <v-alert border="left" colored-border text :color="alert.color" :icon="alert.icon" prominent>
              <div class="mb-2 mb-sm-0">
                <p>{{ alert.message }}</p>
                <p class="mb-1">
                  <span class="font-weight-semibold">Objetivo: </span>
                  <span>{{
                    configuracao.objetivo
                    ? configuracao.objetivo
                    : "Nenhum valor configurado."
                  }}</span>
                </p>
                <p class="mb-1">
                  <span class="font-weight-semibold">Total apurado: </span>
                  <span v-if="totalApurado && configuracao.unidade">{{
                    totalApurado | processFloat(configuracao.unidade)
                  }}</span>
                  <span v-else>Nenhum valor configurado.</span>
                </p>
              </div>
            </v-alert>
            <v-row class="mt-1" v-if="configuracao.unidade != null">
              <template v-for="(n, i) in mesesPeriodicidades">
                <Grid :tamanho="mesesPeriodicidades > 4
                  ? 4
                  : mesesPeriodicidades == 1
                    ? 12
                    : 6
                  " :key="i">
                  <v-text-field v-model="distribuicaoValores[`${i}`].value" outlined
                    data-vv-scope="distribuicao-valores-inputs" :id="`input${i}`" :rules="[validators.required]"
                    hide-details="auto" dense class="mb-6" placeholder="Descreva o valor"
                    v-mask="unidadesMedidas[configuracao.unidade].mask"
                    :prefix="unidadesMedidas[configuracao.unidade].prefix" :label="`Mês ${n}`"
                    @blur="updateInputsGoal()"></v-text-field>
                </Grid>
              </template>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import store from "@/store";
import { ref, computed, watchEffect } from "@vue/composition-api";
import Grid from "@/components/Grid.vue";
import FormConfiguracaoMeta from "./../tabs/FormConfiguracaoMeta.vue";
import { required, requiredNumeber } from "@core/utils/validation";

import { unMaskNumber } from '@/helpers';

export default {
  name: "distribuicao-valores-Component",
  components: { Grid, FormConfiguracaoMeta },
  inject: ['$validator'],
  setup(_, { emit }) {
    // COMPUTED
    const mesesPeriodicidades = computed(
      () => store.getters["avdTecnicas/getMesesPeriodicidade"]
    );
    const avaliacao = computed(() => store.getters["avd/getAvaliacao"]);
    const configuracao = computed(
      () => store.getters["avdTecnicas/getConfiguracao"]
    );
    const habilitarDistribuicaoValores = computed(
      () => store.getters["avdTecnicas/getHabilitarDistribuicaoValores"]
    );
    const distribuicaoValores = computed(
      () => store.getters["avdTecnicas/getDistribuicaoValores"]
    );
    const totalApurado = computed({
      get() {
        return store.getters["avdTecnicas/getTotalApurado"];
      },
      set(value) {
        store.dispatch("avdTecnicas/setTotalApurado", value);
      },
    });

    //VARIABLE
    const calculoAutomatico = ref(false);
    const totalPreenchido = ref(0);
    const dataAlert = ref({
      danger: {
        icon: "mdi-alert-circle-outline",
        color: "error",
        message:
          "A soma dos valores acima é superior ou inferior ao valor estipulado. Corrija antes de salvar a meta.",
      },
      info: {
        icon: "mdi-information-outline",
        color: "primary",
        message: "Valores configurados corretamente",
      },
      start: {
        icon: "mdi-information-outline",
        color: "primary",
        message: "Valores não configurados ainda",
      }
    });
    const alert = ref({
      icon: "mdi-information-outline",
      color: "primary",
      message: "Valores não configurados ainda",
    });
    const unidadesMedidas = {
      RE: {
        mask: [
          '#,##',
          '##,##',
          '###,###',
          '#.###,##',
          '##.###,##',
          '###.###,##',
          '#.###.###,##'
        ],
        prefix: 'R$'
      },
      DO: {
        mask: [
          '#.##',
          '##.##',
          '###.###',
          '#,###.##',
          '##,###.##',
          '###,###.##',
          '#,###,###.##'
        ],
        prefix: 'US$'
      },
      UN: {
        mask: [
          '#',
          '##',
          '####',
          '#.###',
          '##.###',
          '###.###',
          '#.###.###'
        ],
        prefix: 'UN'
      },
      PO: {
        mask: [
          '#,##',
          '##,##',
          '###,##',
        ],
        prefix: '%'
      }
    }
    const formConfiguracaoMeta = ref(null);

    //FUNCTIONS
    function calcularAutomatico() {
      if (configuracao.value && configuracao.value.objetivo !== undefined) {
        let valorMensal = parseFloat(
          configuracao.value.objetivo.replace(".", "").replace(",", ".") /
          avaliacao.value.total_meses
        ).toFixed(2);

        for (let index = 0; index < distribuicaoValores.value.length; index++) {
          distribuicaoValores.value[index] = valorMensal;
        }
        calculoAutomatico.value = !calculoAutomatico.value;
      }
    }
    function updateInputsGoal() {
      let objetivoGeral = unMaskNumber(
        configuracao.value.objetivo, configuracao.value.unidade
      );

      // Pega a quantidade de meses, desmascara, soma e retorna o valor somado
      let totalMeses = 0;
      distribuicaoValores.value.map((e) => {
        totalMeses += unMaskNumber(e.value, configuracao.value.unidade);
      });
      totalApurado.value = totalMeses;

      alert.value =
        totalApurado.value > objetivoGeral
          ? dataAlert.value.danger
          : dataAlert.value.info;
    };
    function validateValueObjectiveNegative() {
      return configuracao.value.objetivo &&
        configuracao.value.objetivo != null &&
        configuracao.value.objetivo != "" &&
        configuracao.value.objetivo.split(",")[0] != 0
        ? true
        : false;
    };
    async function validate() {
      const validate1 = await this.$validator.validate('distribuicao-valores-inputs.*');
      const validate2 = await formConfiguracaoMeta.value.validate();

      return validate1 && validate2 ? true : false;
    }


    return {
      // Variables
      mesesPeriodicidades,
      distribuicaoValores,
      configuracao,
      calculoAutomatico,
      totalApurado,
      formConfiguracaoMeta,
      dataAlert,
      alert,
      unidadesMedidas,

      //Functions
      calcularAutomatico,
      updateInputsGoal,
      validateValueObjectiveNegative,
      validate,
      validators: {
        required,
        requiredNumeber,
      },
      habilitarDistribuicaoValores,
    };
  },
};
</script>

<style></style>
