import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-3"},[_c(VCard,{staticClass:"mt-1",attrs:{"outlined":""}},[_c(VCardTitle,{staticClass:"text-no-wrap pt-1 ps-2 mt-2 mb-1"},[_vm._v(" Configuração da meta ")]),_c(VCardSubtitle,{staticClass:"text-no-wrap ps-2"},[_vm._v(" Descrição detalhada que explique ao usuário a sua funcionalidade e importancia ")]),_c(VCardText,{staticClass:"d-flex align-center mt-2 pb-2 ps-2"},[_c('FormConfiguracaoMeta',{ref:"formConfiguracaoMeta"})],1)],1),_c(VCard,{staticClass:"mt-2",attrs:{"outlined":""}},[_c(VCardTitle,{staticClass:"text-no-wrap pt-1 ps-2 mt-2 mb-1"},[_vm._v(" Distribuição de valores ")]),_c(VCardSubtitle,{staticClass:"text-no-wrap ps-2"},[_vm._v(" Descrição detalhada da distribuição de valores que explique ao usuário a sua funcionalidade e importancia ")]),_c(VCardText,{staticClass:"d-flex align-center mt-2 pb-2 ps-2"},[_c(VRow,{staticClass:"mt-2 pa-3"},[_c(VCol,{attrs:{"cols":"12"}},[_c(VDivider)],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VAlert,{attrs:{"border":"left","colored-border":"","text":"","color":_vm.alert.color,"icon":_vm.alert.icon,"prominent":""}},[_c('div',{staticClass:"mb-2 mb-sm-0"},[_c('p',[_vm._v(_vm._s(_vm.alert.message))]),_c('p',{staticClass:"mb-1"},[_c('span',{staticClass:"font-weight-semibold"},[_vm._v("Objetivo: ")]),_c('span',[_vm._v(_vm._s(_vm.configuracao.objetivo ? _vm.configuracao.objetivo : "Nenhum valor configurado."))])]),_c('p',{staticClass:"mb-1"},[_c('span',{staticClass:"font-weight-semibold"},[_vm._v("Total apurado: ")]),(_vm.totalApurado && _vm.configuracao.unidade)?_c('span',[_vm._v(_vm._s(_vm._f("processFloat")(_vm.totalApurado,_vm.configuracao.unidade)))]):_c('span',[_vm._v("Nenhum valor configurado.")])])])]),(_vm.configuracao.unidade != null)?_c(VRow,{staticClass:"mt-1"},[_vm._l((_vm.mesesPeriodicidades),function(n,i){return [_c('Grid',{key:i,attrs:{"tamanho":_vm.mesesPeriodicidades > 4
                ? 4
                : _vm.mesesPeriodicidades == 1
                  ? 12
                  : 6}},[_c(VTextField,{directives:[{name:"mask",rawName:"v-mask",value:(_vm.unidadesMedidas[_vm.configuracao.unidade].mask),expression:"unidadesMedidas[configuracao.unidade].mask"}],staticClass:"mb-6",attrs:{"outlined":"","data-vv-scope":"distribuicao-valores-inputs","id":("input" + i),"rules":[_vm.validators.required],"hide-details":"auto","dense":"","placeholder":"Descreva o valor","prefix":_vm.unidadesMedidas[_vm.configuracao.unidade].prefix,"label":("Mês " + n)},on:{"blur":function($event){return _vm.updateInputsGoal()}},model:{value:(_vm.distribuicaoValores[("" + i)].value),callback:function ($$v) {_vm.$set(_vm.distribuicaoValores[("" + i)], "value", $$v)},expression:"distribuicaoValores[`${i}`].value"}})],1)]})],2):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }