<template>
  <v-row>
    <v-col col="12" md="6" sm="12">
      <v-select item-text="text" item-value="value" :items="listaTipoTecnica" data-vv-scope="form-configuracao-meta"
        :hint="errors.first('tipo_distribuicao')" :error="errors.collect('tipo_distribuicao').length ? true : false"
        persistent-hint required v-validate="'required'" data-vv-validate-on="change" id="tipo_distribuicao"
        name="tipo_distribuicao" ref="tipo_distribuicao" v-model="configuracao.tipo_tecnica" outlined hide-details dense
        class="select-router-transition" label="Tipo da distribuição"></v-select>
    </v-col>
    <v-col col="12" md="6" sm="12">
      <v-select item-text="text" item-value="value" :items="listaTipos" data-vv-scope="form-configuracao-meta"
        :hint="errors.first('tipo_meta')" :error="errors.collect('tipo_meta').length ? true : false" persistent-hint
        required v-validate="'required'" data-vv-validate-on="change" id="tipo_meta" name="tipo_meta" ref="tipo_meta"
        v-model="configuracao.tipo_distribuicao" :rules="[validators.required]" outlined hide-details dense
        class="select-router-transition" label="Tipo da meta"></v-select>
    </v-col>
    <v-col col="12" md="6" sm="12">
      <v-select item-text="text" item-value="value" :items="listaUnidadesMedida" data-vv-scope="form-configuracao-meta"
        :hint="errors.first('unidade_medida')" :error="errors.collect('unidade_medida').length ? true : false"
        persistent-hint required v-validate="'required'" data-vv-validate-on="change" id="unidade_medida"
        name="unidade_medida" ref="unidade_medida" v-model="configuracao.unidade" :rules="[validators.required]" outlined
        hide-details dense class="select-router-transition" label="Unidade de medida"></v-select>
    </v-col>
    <v-col col="12" md="6" sm="12">
      <v-select item-text="text" item-value="value" :items="listaMelhorSe" data-vv-scope="form-configuracao-meta"
        :hint="errors.first('melhor_se')" :error="errors.collect('melhor_se').length ? true : false" persistent-hint
        required v-validate="'required'" data-vv-validate-on="change" id="melhor_se" name="melhor_se" ref="melhor_se"
        v-model="configuracao.melhor_se" :rules="[validators.required]" outlined hide-details dense
        class="select-router-transition" label="Melhor se"></v-select>
    </v-col>
    <v-col col="12" md="6" sm="12">
      <v-select item-text="text" item-value="value" :items="listaVerbo" data-vv-scope="form-configuracao-meta"
        :hint="errors.first('verbo_meta')" :error="errors.collect('verbo_meta').length ? true : false" persistent-hint
        required v-validate="'required'" data-vv-validate-on="change" id="verbo_meta" name="verbo_meta" ref="verbo_meta"
        v-model="configuracao.verbo" :rules="[validators.required]" outlined hide-details dense
        class="select-router-transition" label="Verbo da meta"></v-select>
    </v-col>
    <v-col col="12" md="6" sm="12">
      <v-text-field data-vv-scope="form-configuracao-meta" :hint="errors.first('complemento_meta')"
        :error="errors.collect('complemento_meta').length ? true : false" persistent-hint required v-validate="'required'"
        data-vv-validate-on="change" id="complemento_meta" name="complemento_meta" ref="complemento_meta"
        v-model="configuracao.complemento" outlined :rules="[validators.required]" hide-details dense
        label="Complemento da meta"></v-text-field>
    </v-col>
    <v-col col="12" md="6" sm="12">
      <v-text-field v-if="configuracao.unidade" data-vv-scope="form-configuracao-meta"
        :hint="errors.first('objetivo_meta')" :error="errors.collect('objetivo_meta').length ? true : false"
        persistent-hint required v-validate="'required'" data-vv-validate-on="change" id="objetivo_meta"
        name="objetivo_meta" ref="objetivo_meta" v-model="configuracao.objetivo" :rules="[validators.required]" outlined
        v-mask="unidadesMedidas[configuracao.unidade].mask" :prefix="unidadesMedidas[configuracao.unidade].prefix"
        hide-details dense label="Objetivo da meta"></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import store from "@/store";
import { required, requiredNumeber } from "@core/utils/validation";
import { onMounted, ref, computed, watchEffect } from "@vue/composition-api";

import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export default {
  name: "form-configuracao-meta-Component",
  components: {},
  inject: ['$validator'],
  setup(_, { emit }) {
    const configuracao = computed(
      () => store.getters["avdTecnicas/getConfiguracao"]
    );
    const listaTipoTecnica = ref([
      { text: "Equipe", value: 'E' },
      { text: "Individual", value: 'P' },
    ]);
    const listaTipos = ref([
      { text: "Quantitativa", value: 'quantitativa' },
      { text: "Qualitativa", value: 'qualitativa' },
    ]);
    const listaUnidadesMedida = ref([
      { text: "R$ - Reais", value: 'RE' },
      { text: "US$ - Dolar", value: 'DO' },
      { text: "UN - Unidade", value: 'UN' },
      { text: "% - Percentual", value: 'PO' },
    ]);
    const listaMelhorSe = ref([
      { text: "Reduzir", value: 'menor' },
      { text: "Aumentar", value: 'maior' },
    ]);
    const listaVerbo = ref([
      { text: "Alcançar", value: "Alcançar" },
      { text: "Atingir", value: "Atingir" },
      { text: "Aumentar", value: "Aumentar" },
      { text: "Conquistar", value: "Conquistar" },
      { text: "Controlar", value: "Controlar" },
      { text: "Distribuir", value: "Distribuir" },
      { text: "Entregar", value: "Entregar" },
      { text: "Lançar", value: "Lançar" },
      { text: "Produzir", value: "Produzir" },
      { text: "Realizar", value: "Realizar" },
      { text: "Reduzir", value: "Reduzir" },
      { text: "Vender", value: "Vender" },
      { text: "Visitar", value: "Visitar" },
    ]);
    const unidadesMedidas = {
      RE: {
        mask: [
          '#,##',
          '##,##',
          '###,###',
          '#.###,##',
          '##.###,##',
          '###.###,##',
          '#.###.###,##'
        ],
        prefix: 'R$'
      },
      DO: {
        mask: [
          '#.##',
          '##.##',
          '###.###',
          '#,###.##',
          '##,###.##',
          '###,###.##',
          '#,###,###.##'
        ],
        prefix: 'US$'
      },
      UN: {
        mask: [
          '#',
          '##',
          '###',
          '#.###',
          '##.###',
          '###.###',
          '#.###.###'
        ],
        prefix: 'UN'
      },
      PO: {
        mask: [
          '#,##',
          '##,##',
          '###,##',
        ],
        prefix: '%'
      }
    }

    async function validate() {
      return await this.$validator.validate('form-configuracao-meta.*');
    }

    watchEffect(() => {
      if (
        configuracao.value.unidade != null
      ) {

        store.dispatch("avdTecnicas/habilitarCamposDistribuicaoValores", true);
        // } else {
        // let arrayDistribuicaoValores = [];
        // store.dispatch("avdTecnicas/setDistribuicaoValores", arrayDistribuicaoValores);
        // store.dispatch("avdTecnicas/habilitarCamposDistribuicaoValores", false);
        // store.dispatch("avdTecnicas/calculaDadosDistribuicaoDeValores");
      }
    });

    return {
      //computeds
      configuracao,

      //variaveis
      listaTipos,
      listaUnidadesMedida,
      listaMelhorSe,
      listaVerbo,
      listaTipoTecnica,
      validators: {
        required,
        requiredNumeber,
      },
      unidadesMedidas,

      // Methods
      validate,
    };
  },
};
</script>

<style></style>